// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// app
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LaunchpointCoreClientAuthGuard } from '@launchpoint/core-client';
import { SignUpGuard } from './modules/sign-up/sign-up.guard';
import { CompanySignUpGuard } from './modules/company-sign-up/company-sign-up.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@launchpoint/core-web').then((m) => m.LaunchpointCoreWebAuthV2Module),
  },
  {
    path: 'sign-up',
    canActivate: [LaunchpointCoreClientAuthGuard], //SignUpGuard
    loadChildren: () => import('./modules/company-sign-up/company-sign-up.module').then((m) => m.CompanySignUpModule),
  },
  {
    path: 'checkout',
    canActivate: [LaunchpointCoreClientAuthGuard], //SignUpGuard
    loadChildren: () => import('./modules/app-checkout/app-checkout.module').then((m) => m.AppCheckoutModule),
  },
  {
    path: 'error',
    loadChildren: () => import('@launchpoint/core-web').then((m) => m.AppErrorsCoreModule),
  },
  // {
  //   path: 'sign-up',
  //   canActivate: [LaunchpointCoreClientAuthGuard],
  //   loadChildren: () => import('./modules/sign-up/sign-up.module').then((m) => m.SignUpModule),
  // },
  {
    path: '',
    canActivate: [LaunchpointCoreClientAuthGuard], //SignUpGuard
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //, {enableTracing:true}
  exports: [RouterModule],
})
export class AppRoutingModule {}
