import { IErrorModuleConfig } from '@launchpoint/core-client';

export const ERROR_CONFIG: IErrorModuleConfig = {
  background_image: './assets/media/illustrations/sketchy-1/14.png',
  logo: './assets/media/logos/launchpoint.svg',
  footers: [
    {
      title: 'About',
      href: 'https://launchpoint.dev',
    },
    {
      title: 'Contact',
      href: 'https://launchpoint.dev',
    },
  ],
  return_route_button_title: 'Return Home',
  route: 'error',
  pages_400: {
    title: 'Page Not Found',
    description: 'The page you looked not found! \n Plan your blog post by choosing a topic',
  },
};
