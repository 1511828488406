import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { VAPID_KEY } from '@angular/fire/compat/messaging';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APPLICATION_CONFIG_TOKEN,
  CustomerSerializer,
  LaunchpointSecurityModule,
  ROUTER_EFFECTS,
  RouterToken,
  USER_EFFECTS,
  UserAuthActions,
  UserSelectors,
} from '@launchpoint/core-client';
import {
  AppErrorsCoreModule,
  LaunchpointCoreWebAuthInterceptor,
  LaunchpointCoreWebAuthV2Module,
  LaunchpointCoreWebProfileModule,
  USER_REDUCERS,
  UserViewsModule,
} from '@launchpoint/core-web';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { registerLicense } from '@syncfusion/ej2-base';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxStripeModule } from 'ngx-stripe';
import { TagifyModule } from 'ngx-tagify';
import { catchError, of, timeout } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AUTH_CONFIG } from './core/config/auth.config';
import { ERROR_CONFIG } from './core/config/error.config';
import { PROFILE_CONFIG } from './core/config/profile-config';
import { CoreModule } from './core/core.module';
import { ABAC_SECURITY_MODULES } from './security/abac';

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV1dX2dWfFN0QXNQdV1zflFGcC0sT3RfQFljSHxWd0xjXHtceXxWRA==');

function appInitializer(store: Store) {
  return () => {
    store.dispatch(UserAuthActions.getUserByToken());
    return new Promise((resolve) => {
      store
        .select(UserSelectors.selectUserInitCheckComplete)
        .pipe(
          timeout(15000),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          catchError((val) => of(resolve(undefined)))
        )
        .subscribe((data) => {
          if (data) {
            resolve(data);
          }
        })
        .add(resolve(undefined));
    });
  };
}

const LaunchpointCoreModules = [
  //
  LaunchpointCoreWebAuthV2Module.forRoot(AUTH_CONFIG),
  AppErrorsCoreModule.forRoot(ERROR_CONFIG),
  LaunchpointCoreWebProfileModule.forRoot(PROFILE_CONFIG),
  LaunchpointSecurityModule.register({
    security_modules: ABAC_SECURITY_MODULES,
  }),
  UserViewsModule.forRoot({ base_url: environment.apiUrl }),
];

const FIREBASE = [
  // AngularFireModule.initializeApp(environment.firebase),
  // AngularFireMessagingModule,
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideMessaging(() => getMessaging()),
];

@NgModule({
  imports: [
    CoreModule,
    // SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    ClipboardModule,
    InlineSVGModule.forRoot(),
    TagifyModule,
    NgxStripeModule.forRoot('pk_test_51NpWhOFPXmGSDiFQHLeK6nvYuFHNEPBG0PxzIq9O6Zx3wMteKJPdlPEmQ9FzVfAYwVv8udfBYnwB18jPU6jDvQU800kT6wsEDR'),
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1Ijoicm9iZXJ0c3RhZG5pY2siLCJhIjoiY2xsb210bG5lMGFqZzNmbXdibnVtMG03aSJ9.AH2Xk8FgDZAaareqY4j1fA', // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    // STORE
    // ...FIREBASE,
    StoreModule.forRoot(USER_REDUCERS, {
      metaReducers: !environment.production ? [] : [],
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([...ROUTER_EFFECTS, ...USER_EFFECTS]),
    !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
    // Custom Modules
    ...LaunchpointCoreModules,
  ],
  providers: [
    ...FIREBASE,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [Store],
    },
    { provide: VAPID_KEY, useValue: environment.vapidKey },
    {
      provide: APPLICATION_CONFIG_TOKEN,
      useValue: {
        environment,
        layoutConfig: {
          logo_dark: './assets/media/logos/launchpoint-logo-full-darkmode.svg',
          logo_light: './assets/media/logos/launchpoint.svg',
        },
      },
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomerSerializer,
    },
    { provide: RouterToken, useValue: null },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LaunchpointCoreWebAuthInterceptor,
      multi: true,
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
