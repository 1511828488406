import { ABAC_AUTH_MODULE, ELaunchpointUserRoles, ISecurityConfig } from '@launchpoint/core-types';
import { ABAC_LAUNCHPOINT_PAYMENTS } from '@launchpoint/payments-types';

export enum ELaunchpointAdmin {
  VIEW_ADMIN = 'view-admin',
}

export enum ELaunchpointAdminSubject {
  APP = 'application',
}

const ABAC_ADMIN_ACCESS: ISecurityConfig = {
  acl: {
    [ELaunchpointUserRoles.SUPER_ADMIN]: [
      {
        effect: 'can',
        permissions: [
          {
            actions: Object.values(ELaunchpointAdmin),
            subject: ELaunchpointAdminSubject.APP,
          },
        ],
      },
    ],
  },
  debug: false,
};

export const ABAC_SECURITY_MODULES = [ABAC_ADMIN_ACCESS, ABAC_AUTH_MODULE, ABAC_LAUNCHPOINT_PAYMENTS];
