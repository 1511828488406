import { ICoreAuthConfig } from '@launchpoint/core-client';

export const AUTH_CONFIG: ICoreAuthConfig = {
  auth: {
    base_url: 'api/backend/',
    background_color: '#2d2f46',
    // background_image: './assets/auth/auth-bg.png',
    logo: './assets/auth/auth-logo-yellow.png',
  },
  logo_light: './assets/logo/logo-only-100x.png',
  logo_dark: './assets/logo/logo-only-100x.png',
  registration: {
    active: true,
    title: 'Create A AccuServPro Account',
    description: "Let's create your account.",
    input_label: 'Email', // or Phone Number
    require_password: true,
    allow_phone: false,
    prefix_agreement_text: 'I agree to',
    primary_agreement_href: 'https://launchpoint.dev/terms-and-conditions',
    primary_language: 'Terms and Conditions',
    transition_text_1: 'and ',
    secondary_agreement_href: 'https://launchpoint.dev/privacy-policy',
    secondary_language: 'Privacy Policy',
    agreements_required: true,
  },
  login: {
    title: 'Sign In',
    button: 'Sign In',
  },
  set_password: {
    title: 'Create Password',
    button: 'Sign Up',
    password_settings: {
      min_length: 8,
      max_length: 100,
      require_number: false,
      require_special: false,
      require_uppercase: false,
    },
  },
  verify: {
    title: 'One Time Code',
    description: 'Please enter the code sent to: ',
    code_length: 6,
    auto_submit: true,
  },
  third_party: {
    google: {
      active: false,
      href: './assets/media/svg/brand-logos/google-icon.svg',
    },
  },
  footers: [
    {
      title: 'About',
      href: 'https://launchpoint.dev',
    },
    {
      title: 'Contact',
      href: 'https://launchpoint.dev',
    },
    {
      title: 'Terms and Conditions',
      href: 'https://launchpoint.dev',
    },
  ],
};
