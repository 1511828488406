import { LaunchpointCoreClientProfileConfig } from '@launchpoint/core-client';
import {
  ELaunchpointAccountRoles,
  ELaunchpointUserRoles,
} from '@launchpoint/core-types';
import { environment } from '@accuservpro/xplat/core';

export const PROFILE_CONFIG: LaunchpointCoreClientProfileConfig = {
  default_image: '/assets/media/avatars/blank.png',
  // icon: 'fa fa-info-circle',
  // icon_svg: './assets/media/icons/duotune/general/gen026.svg',
  // badge: 'Upgrade To Pro',
  base_url: environment.apiUrl,

  profile_details: {
    title: 'Profile Details',
    fields: {
      first_name: true,
      last_name: true,
      birthday: true,
      gender: true,
      marital_status: true,
    },
  },
  security_roles: Object.values(ELaunchpointUserRoles),
  account_security_roles: Object.values(ELaunchpointAccountRoles),
};
