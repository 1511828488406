import { ChangeDetectionStrategy, Component } from '@angular/core';

// xplat
import { AppBaseComponent } from '@accuservpro/xplat/web/features';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  template: '<router-outlet></router-outlet>',
  // styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends AppBaseComponent {
  // constructor(private translationService: TranslationService) {
  //   super();
  //   this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
  // }
}
