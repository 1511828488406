// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironmentApp } from '@launchpoint/core-client';

export const environment: IEnvironmentApp = {
  production: false,
  appVersion: 'v8.0.28',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api/backend/',
  mediaApiUrl: 'api/media/',
  notificationsApiUrl: 'api/notifications/',
  firebase: {
    apiKey: 'AIzaSyDFqRlEtJfDbwF5KXqaWcrFjAdDakfWpDs',
    authDomain: 'accuservpro-d0f4d.firebaseapp.com',
    projectId: 'accuservpro-d0f4d',
    storageBucket: 'accuservpro-d0f4d.appspot.com',
    messagingSenderId: '1028306650840',
    appId: '1:1028306650840:web:5077d66b51659361ffb9ad',
    measurementId: 'G-L6BMXQ5H1J',
  },
  vapidKey: 'ZyYdTIK1yyFrP3ZU_e6NRRPXtfwWhtBHehuHWTNW4IE',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
